import "./App.css";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { useState, Fragment } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

const url =
  "//immosuite.us5.list-manage.com/subscribe/post?u=2df09b06bdd3cd64d44281cdc&id=906564998a";

export function Confirmation({t, lng}) {
  const [show, setShow] = useState(true);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                    {t(`allSet`, { lng })}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                    {t(`weWillInformYou`, { lng })}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          title: "Digital real estate management",
          attributes: "Simple, comprehensive and integrated",
          introduction:
            "We are working on something big: a digital management solution that lives up to its name. No gag contracts, no compromises and compatible with your existing solution.",
          yourEmail: "Your email address",
          signUp: "Get News",
          allSet: "All set.",
          weWillInformYou: "As soon we've got news, will get in touch with you.",
          signUpBenefits:
            "Be the first to get news about our product. We are looking forward to present you our solution soon.",
        },
      },
      de: {
        translation: {
          introduction:
            "Wir arbeiten an etwas Grossem: Eine digitale Verwaltungslösung, die ihrem Namen gerecht wird. Keine Knebelverträge, keine Kompromisse und kompatibel mit ihrer bestehenden Lösung.",
          title: "Digitale Liegenschaftsverwaltung",
          attributes: "einfach, umfassend und vernetzt",
          yourEmail: "Ihre E-Mail-Adresse",
          signUp: "Newsletter abonnieren",
          allSet: "Alles klar.",
          weWillInformYou: "Sobald es Neuigkeiten gibt, werden wir Sie kontaktieren.",
          signUpBenefits:
            "Erfahren Sie als Erstes Neuigkeiten zu unserem Produkt. Wir freuen uns, Ihnen schon bald unsere Lösung präsentieren zu dürfen.",
        },
      },
    },
    lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const { t } = useTranslation();
  const [lng, setLng] = useState("de");
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  return (
    <div className=" bg-gray-900 overflow-hidden min-h-screen">
      <div className="relative z-10 lg:overflow-hidden">
        {sent && <Confirmation t={t} lng={lng} />}
        <main>
          <div className="sm:h-auto lg:h-screen  lg:pt-4  lg:pb-8 lg:overflow-hidden">
            <div className="mx-auto  relative z-1 max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-4">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-0 -mt-6">
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold  text-white sm:mt-5 sm:text-6xl lg:mt-0 xl:text-6xl">
                      <div className="mb-4 absolute z-50">
                        <span
                          className={
                            (lng === "de" ? "font-bold" : "font-normal") +
                            " text-sm cursor-pointer tracking-wide font-light"
                          }
                          onClick={() => setLng("de")}
                        >
                          deu&nbsp;&middot;&nbsp;
                        </span>
                        <span
                          onClick={() => setLng("en")}
                          className={
                            (lng === "en" ? "font-extrabold" : "font-normal") +
                            " text-sm cursor-pointer tracking-wide font-light"
                          }
                        >
                          eng
                        </span>
                      </div>
                      <span
                        className="block lg:mt-0 pt-16"
                        onClick={() => setLng(lng === "en" ? "de" : "en")}
                      >
                        immosuite
                      </span>
                      <span className="pb-3  text-4xl pt-3 block bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 sm:pb-5">
                        {t(`title`, { lng })}
                        <br />
                        {t(`attributes`, { lng })}
                      </span>
                    </h1>
                    <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                      {t(`introduction`, { lng })}
                    </p>
                    <div className="mt-10 sm:mt-12">
                      <form
                        action="#"
                        className="sm:max-w-xl sm:mx-auto lg:mx-0"
                      >
                        <MailchimpSubscribe
                          url={url}
                          render={({ subscribe, status, message }) => (
                            <div className="sm:flex">
                              <div className="min-w-0 flex-1">
                                <label htmlFor="email" className="sr-only">
                                  {t(`yourEmail`, { lng })}
                                </label>
                                <input
                                  id="email"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.currentTarget.value);
                                  }}
                                  type="email"
                                  placeholder={t(`yourEmail`, { lng })}
                                  className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                                />
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3">
                                <button
                                  disabled={email.includes("@") === false}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    subscribe({ EMAIL: email });
                                    setSent(true);

                                    setTimeout(() => setSent(false), 5000);
                                  }}
                                  type="submit"
                                  className={
                                    "disabled:opacity-50 block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                                  }
                                >
                                  {t(`signUp`, { lng })}
                                </button>
                              </div>
                            </div>
                          )}
                        />
                        <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                          {t(`signUpBenefits`, { lng })}
                        </p>
                        <p className="pt-4 text-sm text-gray-200 opacity-25 sm:mt-4">
                          &copy; 2021 &middot; immosuite AG (in Gründung)
                          &middot; Zürich &middot; Schweiz
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="sm:top-0 sm:absolute static mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                  <div className="z-0 mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    <img
                      className="w-full absolute inset-y-0 opacity-25 lg:opacity-100 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
